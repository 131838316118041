// Global Variables
// Colors
$green-light: #b1de00;
$green-dark: #468902;
$blue-light: #628290;
$blue-dark: #133954;
$gray-light: #b4b4b4;
$dark-dark: #000000;
$dark-almost: #1b1c1c;

// Dimensions

$fixed-navbar-height: 56px;
$desktop-footer-height: 145px;
$mobile-footer-height: 195px;

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

body {
    background-color: $dark-almost;
    color: lightgrey;
    font-family: "Cabin", sans-serif;
}

// Text
.playfair-display {
    font-family: "Playfair Display", Sans-serif;
    line-height: $line-height-lg;
}

.permanent-marker {
    font-family: "Permanent Marker", cursive;
}

.quote-background {
    background-color: #333333bd;
}

a {
    color: $green-dark;

    &:hover {
        color: $green-light;
    }
}

code {
    color: $blue-light;
}

.light-text {
    color: lightgrey;
}

h1, h2, h3, h4, h6, p {
    margin: 0;
}

// Navbar

.navbar {
    z-index: 1000;
}
.navbar-spacer {
    height: $fixed-navbar-height;
    padding: 0;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.main-content-height {
    height: calc(100vh - #{$fixed-navbar-height});
}

.footer-height {
    height: $desktop-footer-height;
}

@media screen and (max-width: map-get($grid-breakpoints, md)) {
    .footer-height {
        height: $mobile-footer-height;
    }
}

// Simplebar
.simplebar-scrollbar::before {
    background-color: $green-light !important;
}

.simplebar-placeholder {
    width: auto !important;
}

.simplebar-mask .simplebar-offset {
    width: 100vw;
}

.simplebar-content-wrapper {
    height: calc(100vh - #{$fixed-navbar-height}) !important;
}

// React
// Background Image

.blockquote {
    border: none;
}

// React Logo

.App-logo {
    width: 3em;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// Styled Words

.styled-word {
    border: 1px solid $blue-light;
    border-radius: 0.95em;
    display: inline-flex;

    img {
        width: 0.9em;
        height: 0.9em;
    }

    h6 {
        display: inline-block;
    }
}

// Bootstrap Overrides

.btn {
    background-color: $green-dark;
    border-color: $green-dark;
    color: white;
}

.btn:hover {
    color: white;
}

.btn:focus {
    box-shadow: 0 0 0 0.25rem $blue-dark;
}

.dropdown-item.active {
    background-color: $gray-light;
}