.banner-img-container {
    height: 22em;
    min-height: 22em;
    width: 100%;
    position: relative;
    overflow: hidden;

    .banner-img {
        object-fit: cover;
        width: 100%;
        position: absolute;
        top: -70%;
        bottom: -130%;
        margin: auto;
    }
}

@media (max-width: map-get($grid-breakpoints, xxl)) {
    .banner-img-container {
        height: 17em;
        min-height: 17em;
    }
}

@media (max-width: map-get($grid-breakpoints, xl)) {
    .banner-img-container {
        height: 14em;
        min-height: 14em;
    }
}

@media (max-width: map-get($grid-breakpoints, md)) {
    .banner-img-container {
        height: 10em;
        min-height: 10em;
    }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
    .banner-img-container {
        height: 7em;
        min-height: 7em;
    }
}

@media (max-width: map-get($grid-breakpoints, xs)) {
    .banner-img-container {
        height: 5em;
        min-height: 5em;
    }
}